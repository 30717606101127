import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../scss/generationChoosePage.css';

const GenerationChoosePage = ({ authData }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleInstaTiktok = () => {
        navigate(`/insta-tiktok/${id}`);
    };

    const handleInstaPost = () => {
        navigate(`/insta-post/${id}`);
    };

    const handleInstaPostContent= () => {
        navigate(`/insta-post-content/${id}`);
    };

    return (
        <section className="generation-choose-page">
            <h3>Wybierz co chcesz wygenerować</h3>
            <p>Post ID: {id}</p>
            <div className="button-container">
                <button onClick={handleInstaTiktok}>Insta Story / Tiktok</button>
                <button onClick={handleInstaPost}>Insta Post</button>
                <button onClick={handleInstaPostContent}>Insta Post Content</button>
            </div>
        </section>
    );
};

export default GenerationChoosePage;
